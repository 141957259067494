import Vue from 'vue';
import { ValidationProvider,ValidationObserver, extend  } from "vee-validate";
import moment from 'moment'
import { required, email, confirmed, required_if, max, image, length, numeric} from "vee-validate/dist/rules";

// Install rules
extend('required', {
    ...required,
    message: 'The {_field_} field must be required',
});
extend('email', {
    ...email,
    message: 'Valid email is required: ex@abc.xyz'
});
extend('numeric', {
    ...numeric,
    message: 'The {_field_} field may only contain positive numeric digits'
});

extend('confirmed', {
    ...confirmed,
    message: 'The {_field_} confirmation does not match'
});

const validateAge = {
  
    validate(value) {
      let currentDate = moment(new Date()).format('YYYY')
      let dateFormat = moment(value, "YYYY-MM-DD").format('YYYY')
      if(dateFormat >= currentDate){
          return false;
      } else {
          let getDiff = currentDate - dateFormat;
          if(getDiff >= 16) {
              return true;
          } else {
              return false;
          }
      }
    }
  };

  const checkPDFDocument = {
    validate(value) {
        let extensions = ['application/pdf']
        let checkValidExt = extensions.includes(value['type']);
        if(checkValidExt) {
            return true;
        } else {
            return false;
        }
    }
}

extend('validatePdf', {
    ...checkPDFDocument,
    message: 'Please upload valid PDF'
})

extend('requiredRadioBtn', {
    ...required,
    message: 'The answer must be required'
})

extend('minAge', {
    ...validateAge,
    message: 'The minimum {_field_} Should be 16'
})


Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);