import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index';


const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  // if (store.getters.getRole == 'admin')  {
  //   next('/')
  // } else {
  //   next('/permit/manager/listing')
  // }
  next(store.getters.getRedirectionUrl)
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated)  {
    if(!store.getters.checkPermission(to.name)){
      next(store.getters.getRedirectionUrl)
      // if(store.getters.getRole == 'admin')
      // {
      //   next('/')
      // } else {
      //   next('/permit/manager/listing')
      // }
    }
    next()
    return
  }
  next('/login')
}

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/second-page',
      name: 'second-page',
      component: () => import('@/views/SecondPage.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Second Page',
        breadcrumb: [
          {
            text: 'Second Page',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      beforeEnter: ifNotAuthenticated,
      component: () => import('@/views/pages/auth/login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      beforeEnter: ifNotAuthenticated,
      component: () => import('@/views/pages/auth/forgotPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/register',
      name: 'register',
      beforeEnter: ifNotAuthenticated,
      component: () => import('@/views/pages/auth/registration.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/verify-email',
      name: 'verify-email',
      beforeEnter: ifNotAuthenticated,
      component: () => import('@/views/pages/auth/verify-email.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/student-profile',
      name: 'student-profile',
      component: () => import('@/views/pages/profile/createStudentProfile.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageLayout: 'partial',
        pageTitle: 'Student Profile',
        breadcrumb: [
          {
            text: 'Student Profile',
            active: true,
          },
        ],
      },
    },
    {
      path: '/students',
      name: 'student-listing',
      component: () => import('@/views/pages/student/list.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Alumnos',
        breadcrumb: [
          {
            text: 'Alumnos',
            active: true,
          },
        ],
      },
    },
    {
      path: '/teachers',
      name: 'teacher-listing',
      component: () => import('@/views/pages/teacher/list.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Teachers',
        breadcrumb: [
          {
            text: 'Teachers',
            active: true,
          },
        ],
      },
    },
    {
      path: '/student-quiz/:id',
      name: 'student-quiz-listing',
      component: () => import('@/views/pages/quiz/multipleQuestionAnswer.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Quiz',
        breadcrumb: [
          {
            text: 'Quiz',
            active: true,
          },
        ],
      },
    },
    {
      path: '/student-quiz-result/:id',
      name: 'student-quiz-result-listing',
      component: () => import('@/views/pages/quiz/result.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Quiz Result',
        breadcrumb: [
          {
            text: 'Quiz Result',
            active: true,
          },
        ],
      },
    },
    {
      path: '/student/:id/detail',
      name: 'student-detail',
      component: () => import('@/views/pages/student/detail.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Detalles del Alumno',
        breadcrumb: [
          {
            text: 'Detalles del Alumno',
            active: true,
          },
        ],
      },
    },
    {
      path: '/teacher/:id/detail',
      name: 'teacher-detail',
      component: () => import('@/views/pages/teacher/detail.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Teacher Detail',
        breadcrumb: [
          {
            text: 'Teacher Detail',
            active: true,
          },
        ],
      },
    },
    {
      path: '/student/:id/edit',
      name: 'student-edit',
      component: () => import('@/views/pages/student/edit.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Student Edit',
        breadcrumb: [
          {
            text: 'Student Edit',
            active: true,
          },
        ],
      },
    },
    ,
    {
      path: '/teacher/:id/edit',
      name: 'teacher-edit',
      component: () => import('@/views/pages/teacher/edit.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Teacher Edit',
        breadcrumb: [
          {
            text: 'Teacher Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/teacher-profile',
      name: 'teacher-profile',
      component: () => import('@/views/pages/profile/createTeacherProfile.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Teacher Profile',
        breadcrumb: [
          {
            text: 'Teacher Profile',
            active: true,
          },
        ],
      },
    },
    {
      path: '/teacher-profile/edit',
      name: 'edit-teacher-profile',
      component: () => import('@/views/pages/profile/editTeacherProfile.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Teacher Profile',
        breadcrumb: [
          {
            text: 'Teacher Profile',
            active: true,
          },
        ],
      },
    },
    {
      path: '/student-profile/edit',
      name: 'edit-student-profile',
      component: () => import('@/views/pages/profile/editStudentProfile.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Student Profile',
        breadcrumb: [
          {
            text: 'Student Profile',
            active: true,
          },
        ],
      },
    },
    {
      path: '/create-teacher',
      name: 'create-teacher',
      component: () => import('@/views/pages/teacher/create.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Create Teacher',
        breadcrumb: [
          {
            text: 'Create Teacher',
            active: true,
          },
        ],
      },
    },
    {
      path: '/create-student',
      name: 'create-student',
      component: () => import('@/views/pages/student/create.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Create Student',
        breadcrumb: [
          {
            text: 'Create Student',
            active: true,
          },
        ],
      },
    },
    {
      path: '/change-password',
      name: 'change-password',
      component: () => import('@/views/pages/auth/changePassword.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Change Password',
        breadcrumb: [
          {
            text: 'Change Password',
            active: true,
          },
        ],
      },
    },
    {
      path: '/unverified-students',
      name: 'unverified-students',
      component: () => import('@/views/pages/emailVerification/student-list.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Alumnos no Verificados',
        breadcrumb: [
          {
            text: 'Alumnos no Verificados',
            active: true,
          },
        ],
      },
    },
    {
      path: '/lessons/:courseId',
      name: 'lesson-course-listing',
      component: () => import('@/views/pages/lesson/listing.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Lesson',
        breadcrumb: [
          {
            text: 'Lesson',
            active: true,
          },
        ],
      },
    },
    {
      path: '/quiz/:lessonId',
      name: 'quiz-lesson-listing',
      component: () => import('@/views/pages/quiz/listing.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Quiz',
        breadcrumb: [
          {
            text: 'Quiz',
            active: true,
          },
        ],
      },
    },
    {
      path: '/mcqs/:quizId',
      name: 'mcq-quiz-listing',
      component: () => import('@/views/pages/multipleQuestion/listing.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Multiple Question Answers',
        breadcrumb: [
          {
            text: 'Multiple Question Answers',
            active: true,
          },
        ],
      },
    },
    {
      path: '/student-lesson',
      name: 'student-lesson-listing',
      component: () => import('@/views/pages/lesson/studentLessonListing.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Lesson',
        breadcrumb: [
          {
            text: 'Lesson',
            active: true,
          },
        ],
      },
    },
    {
      path: '/courses',
      name: 'course-listing',
      component: () => import('@/views/pages/course/listing.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Courses',
        breadcrumb: [
          {
            text: 'Courses',
            active: true,
          },
        ],
      },
    },
    {
      path: '/create-course',
      name: 'course-create',
      component: () => import('@/views/pages/course/create.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Create Course',
        breadcrumb: [
          {
            text: 'Create Course',
            active: true,
          },
        ],
      },
    },

    {
      path: '/edit-course/:id',
      name: 'course-edit',
      component: () => import('@/views/pages/course/edit.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Edit Course',
        breadcrumb: [
          {
            text: 'Edit Course',
            active: true,
          },
        ],
      },
    },
    {
      path: '/upload-course/material',
      name: 'upload-course-material',
      component: () => import('@/views/pages/course/uploadMaterial.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Upload Material',
        breadcrumb: [
          {
            text: 'Upload Material',
            active: true,
          },
        ],
      },
    },
    {
      path: '/upload-course/video',
      name: 'upload-course-video',
      component: () => import('@/views/pages/course/uploadVideo.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Upload Video',
        breadcrumb: [
          {
            text: 'Upload Video',
            active: true,
          },
        ],
      },
    },
    {
      path: '/course-material/:courseId',
      name: 'course-material-listing',
      component: () => import('@/views/pages/course/materialListing.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Course Material',
        breadcrumb: [
          {
            text: 'Course Material',
            active: true,
          },
        ],
      },
    },
    {
      path: '/course-video/:courseId/:monthId',
      name: 'course-video-listing',
      component: () => import('@/views/pages/course/videoListing.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Course Video',
        breadcrumb: [
          {
            text: 'Course Video',
            active: true,
          },
        ],
      },
    },
    {
      path: '/student-course-material',
      name: 'student-course-material',
      component: () => import('@/views/pages/quiz/showMaterial.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Course Material',
        breadcrumb: [
          {
            text: 'Course Material',
            active: true,
          },
        ],
      },
    },
    {
      path: '/student-course-video/:monthId',
      name: 'student-course-video',
      component: () => import('@/views/pages/quiz/showVideo.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Course Video',
        breadcrumb: [
          {
            text: 'Course Video',
            active: true,
          },
        ],
      },
    },
    {
      path: '/edit-course-material/:id',
      name: 'edit-course-material',
      component: () => import('@/views/pages/course/editMaterial.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Course Video',
        breadcrumb: [
          {
            text: 'Course Video',
            active: true,
          },
        ],
      },
    },
    {
      path: '/edit-course-video/:id',
      name: 'edit-course-video',
      component: () => import('@/views/pages/course/editVideo.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Course Video',
        breadcrumb: [
          {
            text: 'Course Video',
            active: true,
          },
        ],
      },
    },
    {
      path: '/add-payment',
      name: 'add-payment',
      component: () => import('@/views/pages/payments/create.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Add Payments',
        breadcrumb: [
          {
            text: 'Add Payments',
            active: true,
          },
        ],
      },
    },
    {
      path: '/edit-payment/:id',
      name: 'edit-payment',
      component: () => import('@/views/pages/payments/edit.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Edit Payments',
        breadcrumb: [
          {
            text: 'Edit Payments',
            active: true,
          },
        ],
      },
    },
    {
      path: '/calendar',
      name: 'components-calendar',
      component: () => import('@/views/pages/calendar/Calendar.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Calendar',
        breadcrumb: [
          
          {
            text: 'Calendar',
            active: true,
          },
        ],
      },
    },
    {
      path: '/student-calendar',
      name: 'components-student-calendar',
      component: () => import('@/views/pages/studentCalendar/Calendar.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Calendar',
        breadcrumb: [
          
          {
            text: 'Calendar',
            active: true,
          },
        ],
      },
    },
    {
      path: '/payment-listing',
      name: 'payment-listing',
      component: () => import('@/views/pages/payments/listing.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Pagos',
        breadcrumb: [
          {
            text: 'Pagos',
            active: true,
          },
        ],
      },
    },
    {
      path: '/student-events',
      name: 'student-events-listing',
      component: () => import('@/views/pages/event/studentEventListing.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Prácticas',
        breadcrumb: [
          {
            text: 'Prácticas',
            active: true,
          },
        ],
      },
    },
    {
      path: '/student-booked-events',
      name: 'student-booked-events-listing',
      component: () => import('@/views/pages/event/studentBookedEvent.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Events',
        breadcrumb: [
          {
            text: 'Events',
            active: true,
          },
        ],
      },
    },
    {
      path: '/events',
      name: 'events-listing',
      component: () => import('@/views/pages/event/listing.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Events',
        breadcrumb: [
          {
            text: 'Events',
            active: true,
          },
        ],
      },
    },
    {
      path: '/find-event-student/:eventId',
      name: 'find-event-student-listing',
      component: () => import('@/views/pages/event/userListing.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Students',
        breadcrumb: [
          {
            text: 'Students',
            active: true,
          },
        ],
      },
    },
    {
      path: '/find-event/:eventId',
      name: 'find-student-event',
      component: () => import('@/views/pages/event/studentEvent.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Event Detail',
        breadcrumb: [
          {
            text: 'Event Detail',
            active: true,
          },
        ],
      },
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('@/views/pages/dashboard/admin.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
      },
    },
    {
      path: '/teacher-dashboard',
      name: 'teacher-dashboard',
      component: () => import('@/views/pages/dashboard/teacher.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
      },
    },
    {
      path: '/student-dashboard',
      name: 'student-dashboard',
      component: () => import('@/views/pages/dashboard/student.vue'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
      },
    },
    {
      path: '/monthly-video/:courseId',
      name: 'admin-monthly-video',
      component: () => import('@/views/pages/course/videoMonthlyListing'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Videos Listing',
        breadcrumb: [
          {
            text: 'Videos Listing',
            active: true,
          },
        ],
      },
    },
    {
      path: '/attachment-months',
      name: 'student-monthly-attachments',
      component: () => import('@/views/pages/course/videoMonthlyStudentListing'),
      beforeEnter: ifAuthenticated,
      meta: {
        pageTitle: 'Months',
        breadcrumb: [
          {
            text: 'Months',
            active: true,
          },
        ],
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      beforeEnter: ifNotAuthenticated,
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    
    {
      path: '*',
      component: () => import('@/views/error/Error404.vue'),
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
