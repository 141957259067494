<template>
    <div>
        <p>Showing {{from}} to {{to}} of {{total}} entries</p>
    </div>
</template>

<script>
    export default {
         props: ['from', 'to', 'total']
    }
</script>