import axiosIns from '@/libs/axios.js';

class AuthService {
    storeStudentProfile(profileData) {
        const config = {
            headers: {
                "content-type": "multipart/form-data"
            }
        }

        return axiosIns.post('student-profile/store', profileData, config).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        });
    }

    storeTeacherProfile(profileData) {
        return axiosIns.post('teacher-profile/store', profileData).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        });
    }

    editStudentsProfile(profileData) {
        return axiosIns.post('student-profile/edit', profileData).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        });
    }
    
}

export default new AuthService();