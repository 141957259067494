import AuthService from '../../services/auth.service';
import ProfileService from '../../services/profile.service';

const state = {
    token: localStorage.getItem('auth.token') || '',
    status: localStorage.getItem('auth.status') || false,
    user:  JSON.parse(localStorage.getItem('auth.user')) || {},
    userDetail: JSON.parse(localStorage.getItem('auth.userDetail')) || {},
    modules: JSON.parse(localStorage.getItem('auth.modules')) || '',
    permissions: JSON.parse(localStorage.getItem('auth.permissions')) || '',
    permissionModuleIds: JSON.parse(localStorage.getItem('auth.permissionModuleIds'))||'',
    role: JSON.parse(localStorage.getItem('auth.role')) || '',
    checkProfile : false,
    redirection_url: localStorage.getItem('auth.redirection') || ''
}
const initialState = {
    token: localStorage.getItem('auth.token') || '',
    status: false,
    user:  {},
    userDetail: {},
    modules: '',
    permissions: '',
    permissionModuleIds: '',
    role: '',
    checkProfile : false,
    profile_image_url: '',
    redirection_url: ''
}

const actions = {
    login({commit}, user) {
        return AuthService.login(user).then(response => {
            commit('loginSuccess', response);
            return Promise.resolve(response);
        }).catch(error => {
            commit('loginFailure');
            return Promise.reject(error);
        })
    },
    logout({commit, state}) {
        return AuthService.logout(state.user).then(response => {
            commit('logout');
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        });
    },
    storeStudentProfile({commit}, data) {
        return ProfileService.storeStudentProfile(data).then(response => {
            commit('changeRole', response);
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        });
    },
    forceLogout({commit}){
        commit('logout');
    }
}

const mutations = {
    loginSuccess(state, response) {
        if (response.data.token) {
            localStorage.setItem('auth.token', JSON.stringify(response.data.token));
            localStorage.setItem('auth.status', JSON.stringify(true));
            localStorage.setItem('auth.user', JSON.stringify(response.data.data.user));
            localStorage.setItem('auth.userDetail', JSON.stringify(response.data.data.userDetail));
            localStorage.setItem('auth.modules', JSON.stringify(response.data.data.modules));
            localStorage.setItem('auth.permissions', JSON.stringify(response.data.data.userRole.permissions));
            localStorage.setItem('auth.permissionModuleIds', JSON.stringify(response.data.data.userRole.module_permissions));
            localStorage.setItem('auth.role', JSON.stringify(response.data.data.userRole.name));
            localStorage.setItem('auth.checkProfile', JSON.stringify(response.data.data.user.is_profile));
            localStorage.setItem('auth.redirection', JSON.stringify(response.data.data.userRole.redirection_url));
            state.userDetail = response.data.data.userDetail;
            state.modules = response.data.data.modules;
            state.permissions = response.data.data.userRole.permissions;
            state.permissionModuleIds = response.data.data.userRole.module_permissions
            state.role = response.data.data.userRole.name
            state.token = response.data.token;
            state.redirection_url = response.data.data.userRole.redirection_url;
        }
        state.status = true;
        state.user = response.data.data.user;
    },
    changeRole(state, response) {
        localStorage.setItem('auth.modules', JSON.stringify(response.data.data.modules));
        localStorage.setItem('auth.permissions', JSON.stringify(response.data.data.userRole.permissions));
        localStorage.setItem('auth.permissionModuleIds', JSON.stringify(response.data.data.userRole.module_permissions));
        localStorage.setItem('auth.role', JSON.stringify(response.data.data.userRole.name));
        localStorage.setItem('auth.redirection', JSON.stringify(response.data.data.userRole.redirection_url));
        state.modules = response.data.data.modules;
        state.permissions = response.data.data.userRole.permissions;
        state.permissionModuleIds = response.data.data.userRole.module_permissions
        state.role = response.data.data.userRole.name
        state.redirection_url = response.data.data.userRole.redirection_url;
    },
    loginFailure(state) {
        state.status = false;
        state.user = null;
    },
    resetState (state) {
        Object.keys(state).forEach(key => {
            state[key] = initialState[key]
        })
    },
    logout(state) {
        localStorage.removeItem('auth.token');
        localStorage.removeItem('auth.status');
        localStorage.removeItem('auth.user');
        localStorage.removeItem('auth.userDetail')
        localStorage.removeItem('auth.modules')
        localStorage.removeItem('auth.permissions')
        localStorage.removeItem('auth.permissionModuleIds')
        localStorage.removeItem('auth.role')
        localStorage.removeItem('auth.redirection')
        state.token = ''
        state.status = false;
        state.user = null;
        state.userDetail = {}
        state.modules = ''
        state.permissions = ''
        state.getPermissionModules = ''
        state.role = ''
        state.redirection_url = ''
    }
}

const getters = {
    isAuthenticated: state => {
        return state.token ? true : false;     
    },
    getUser: state => {
        return state.user ? state.user : '';
    },
     
    getUserDetail: (state) => {
        return state.userDetail ? state.userDetail : '';
    },
    getRedirectionUrl: (state) => {
        // console.log(state.redirection_url)
        // return state.redirection_url ? state.redirection_url : '/permit/manager/listing';
        return state.redirection_url;
    },
    getPermission: state => {
        return state.permissions.split(',')
    },
    getPermissionModules: state => {
        var getModule = [];
        var parentMod = [];
        
        if(state.permissionModuleIds && state.modules) {
            
            var moduleArr = state.permissionModuleIds.split(',');
            getModule = moduleArr.map(m => {
                return state.modules.find(mod => {
                    return mod.id == m 
                })
            }).sort((a,b) => {
                if(a.module_parent == 0 && b.module_parent ==0){
                    return a.module_sequence-b.module_sequence;
                }
            })
            for(var i = 0; i<getModule.length; i++){
                if(getModule[i].module_parent == 0 && getModule[i].link != null){
                    parentMod[getModule[i].name] = getModule[i]
                }
                else if(getModule[i].module_parent == 0 && getModule[i].link == null){
                    let child = [];
                    
                    parentMod[getModule[i].name] = getModule[i]
                    child = getModule.filter(mod => {
                        if(mod.module_parent == getModule[i].id){
                            let nestedChild = [];
                            nestedChild = getModule.filter(childMod => {
                                if(mod.id == childMod.module_parent){
                                    return childMod;
                                }
                            })
                            if(nestedChild.length > 0){
                                mod['childern'] = nestedChild;
                            }
                            return mod
                        }
                    })
                    parentMod[getModule[i].name]['childern'] = child;
                }
            }
        }
        return parentMod;
    },
    checkPermission: (state) => (condition) => {
        return state.permissions.includes(condition)
    },

    getRole: (state) => {
        return state.role;
    },

    getUserDetail: (state) => {
        return state.userDetail ? state.userDetail : '';
    },

    getProfileImg: (state) => {
        if(state.userDetail && state.userDetail.attachment_url) {
            return state.userDetail.attachment_url
        } else {
            return null;
        }
    },

    checkProfileCompleted: (state) => {
        if(state.role == 'student') {
            if(state.checkProfile) {
                return true
            } else {
                return false
            }
        } else {
            return true
        }
    }
}

export default {
    state,
    mutations,
    actions,
    getters,
}