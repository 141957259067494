import Vue from 'vue'
import { ToastPlugin, ModalPlugin, BootstrapVueIcons} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import ShowRecordsCount from './views/components/totalRecords';

import router from './router'
import store from './store'
import App from './App.vue'
import './validations/commonValidations';

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVueIcons)

// Composition API
Vue.use(VueCompositionAPI)
Vue.component("record-count", ShowRecordsCount);

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')
require('@core/scss/vue/libs/vue-sweetalert.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  // beforeCreate() {
  //   this.$store.commit('authInitialStoreState');
  // },
  render: h => h(App),
}).$mount('#app')
