import Vue from 'vue'

// axios
import axios from 'axios'
import store from '../store/index'
import router from '../router/index'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: 'https://aula.anclademia.com/api/',
  //baseURL: 'http://localhost:8000/api/',
  // timeout: 1000,
})
axiosIns.defaults.headers.common['Content-Type'] = 'application/json';
axiosIns.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem('auth.token');
    if (token) {
      config.headers['Authorization'] = 'Bearer '+JSON.parse(token);
    }

    return config;
  }, 
  (error) => {
    return Promise.reject(error);
  }
);
axiosIns.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if(error.response.status == 401){
    store.dispatch('forceLogout')
    router.push('/login')
  }
  return Promise.reject(error)
});
Vue.prototype.$http = axiosIns

export default axiosIns
