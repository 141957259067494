import axiosIns from '@/libs/axios.js';

class AuthService {
    registration(user) {
        return axiosIns.post('student-register', user).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        });
    }
    login(user) {
        return axiosIns.post('access-token', {
            'email': user.email,
            'password': user.password
        });
    }
    logout(user) {
        console.log(user.email)
        return axiosIns.post('logout', {
            'email': user.email
        })
    }
    getCurrentLoggedInUser()
    {
        return axiosIns.get('current-user').then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        });
    }

    changePassword(user)
    {
        return axiosIns.post('change-password', user).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        });
    }

    forgotPassword(email)
    {
        return axiosIns.post('forgot-password', {
            'email': email,
        }).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        });
    }
}

export default new AuthService();

